<template>
  <div class="header-container">
    <div class="header-center-box">
      <div class="header-left-hand">
        <img class="logo" src="@/assets/img/logo.png" />
      </div>
      <div class="header-right-hand">
        <div
          class="header-select-list"
          v-for="(item, index) in selectArr"
          :key="index"
          :class="{ ['header-select-active']: activeSelect == index }"
          @click="handleChangActive(index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
  <div class="content-container">
    <div class="content-center-box">
      <router-view />
    </div>
  </div>
  <div class="footer-container">
    <div>
      <a-space :size="20">
        <span>版权所有</span>
        <span>海宁市曼恩网络科技有限公司</span>
      </a-space>
    </div>
    <div>
      <a class="copy-num" href="https://beian.miit.gov.cn/"> 浙ICP备2023018175号 </a>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, watch } from "vue";
  import { message } from "ant-design-vue";
  import { useRoute, useRouter } from "vue-router";
  import { onBeforeRouteUpdate } from "vue-router";
  // const selectArr = ["首页", "会员充值", "关于我们", "用户协议", "隐私政策"];
  // const routerArr = ["home", "recharge", "aboutus", "agreement", "privacy"];
  const selectArr = ["首页", "关于我们", "用户协议", "隐私政策"];
  const routerArr = ["home", "aboutus", "agreement", "privacy"];
  const activeSelect = ref(0); //高亮选项
  const router = useRouter();
  const route = useRoute();
  function getActiveKey(routername) {
    //进入网页时重置高亮选项
    activeSelect.value = routerArr.findIndex((item) => {
      return routername == item;
    });
  }
  getActiveKey(route.name);
  onBeforeRouteUpdate((to) => {
    //监听路由的变化
    getActiveKey(to.name);
  });
  function checkHTTPS() {
    //上传前调用
    let str = window.location.href;
    console.log(str);
    if (str.search("https")) {
      str = `https${str.substr(4)}`;
      // console.log(str)
      // alert(str)
      window.location.href = str;
    }
  }
  checkHTTPS();
  function checkIsPhone() {
    if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
      //判断设备
      window.location.href = "http://m.7nightapp.com/";
    }
  }
  checkIsPhone();

  function handleChangActive(idx) {
    //切换路由
    activeSelect.value = idx;
    router.push({ path: `/${routerArr[idx]}` });
  }
</script>
<style lang="scss" scoped>
  .header-container {
    position: relative;
    top: 0;
    left: 0;
    height: 66px;
    width: 100%;
    background: #141a26;
    .header-center-box {
      position: absolute;
      width: 1180px;
      padding: 0 13px;
      height: 66px;
      z-index: 999;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header-left-hand {
        width: 267px;
        height: 44px;
        .logo {
          width: 100%;
          height: 100%;
        }
      }
      .header-right-hand {
        display: flex;
        width: 409px;
        height: 66px;
        align-items: center;
        justify-content: space-between;
        color: #717b98;
        font-size: 15px;
        .header-select-list {
          cursor: pointer;
        }
        .header-select-active {
          color: #bfcef8;
        }
      }
    }
  }
  .content-container {
    width: 100%;
    height: calc(100vh - 66px);
    background: #242a37;
    display: flex;
    justify-content: center;
    .content-center-box {
      width: 1280px;
      height: calc(100vh - 66px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .footer-container {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    color: #4d566a;
    font-size: 12px;
    .copy-num {
      margin-top: 10px;
      text-decoration: none;
      color: #4d566a;
    }
  }
</style>
